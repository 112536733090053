import instance from './axios/index';
import { API_AUTH_URL } from 'configs/AppConfig';
const AuthService = {};

AuthService.login = async (username, password) => {
    try {
        let login = 'auth/signin';
        let data = {
            username: username,
            password: password,
        };
        const response = await instance.post(`${API_AUTH_URL}${login}`, data);

        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
    } catch (error) {
        return error;
    }
};
AuthService.logout = async () => {
    try {
        let logout = 'auth/signup';
        await instance.post(`${API_AUTH_URL}${logout}`);
        await instance.post(`${API_AUTH_URL}${logout}`);
        return;
    } catch (error) {
        return error;
    }
};
AuthService.verify = async () => {
    try {
        let verify = 'auth/verify';
        const response = await instance.get(`${API_AUTH_URL}${verify}`);

        if (response.status === 200) {
            return Promise.resolve(response.data);
        }
    } catch (error) {
        return error;
    }
};

export default AuthService;
