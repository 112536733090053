import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN, USER_KEY } from 'constants/AuthConstant';
import AuthService from 'services/AuthService';
import { Modal, Button } from 'antd';
import React from 'react';
import parse from 'html-react-parser';
import ManageLocalStorage from 'utils/local-storage';
import { useTranslation } from 'react-i18next';
import { LIST_MODULE } from 'configs/AppConfig';

const notificationArticle = () => {
    const listModules = LIST_MODULE;
    let arrEntity = [];
    listModules.forEach((elm) => {
        let entity = ManageLocalStorage.get(elm, 'entities');
        if (entity != null)
            entity.forEach((e) => {
                arrEntity.push(e);
            });
    });
    let html = "<ul style='list-style: circle;'>";
    if (arrEntity) {
        arrEntity.forEach((element) => {
            if (element.info.id !== '')
                html += `<li><a href='${element.info.url}/${element.info.id}/?tabId=${element.info.tabId}' target='_blank' >${element.info['desc']}  <b>Xử lý</b></a> </li>`;
            else
                html += `<li><a href='${element.info.url}?tabId=${
                    element.info.tabId
                }' target='_blank' >${`${element.info['desc']}`}  <b>Xử lý</b></a> </li>`;
        });
        html += '</ul>';
    }
    Modal.info({
        title: 'Thông báo về công việc đang chưa xử lý.',

        width: 520,
        content: <div>{parse(html)}</div>,
        footer: (
            <div style={{ float: 'right' }}>
                <Button type="text" href={`/app/apps/notification/notification-list/`}>
                    Xem tất cả
                </Button>
                <Button type="text" href={`/app/dashboards/default`}>
                    Thoát
                </Button>
            </div>
        ),
        // onOk() {},
    });
};

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk('auth/signIn', async (data, { rejectWithValue }) => {
    const { username, password } = data;
    try {
        // const response = await FirebaseService.signInEmailRequest(email, password)

        const response = await AuthService.login(username, password);

        if (response.accessToken) {
            const token = response.accessToken;
            localStorage.setItem(AUTH_TOKEN, response.accessToken);
            localStorage.setItem(
                USER_KEY,
                JSON.stringify({
                    image: response.avartar,
                    displayname: response.displayname,
                    username: response.username,
                    email: response.email,
                    department_id: response.department_id,
                    complete_name: response.complete_name,
                    id:response.id,
                    name: response?.name,
                }),
            );
            return token;
        } else {
            // return rejectWithValue(response.message?.replace('Firebase: ', ''));
            return rejectWithValue('Xác thực không đúng yêu cầu đăng nhập lại');
        }
    } catch (err) {
        return rejectWithValue(err.message || 'Error');
    }
});

export const signOut = createAsyncThunk('auth/signOut', async () => {
    //const response = await FirebaseService.signOutRequest();
    const response = await AuthService.logout();
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_KEY);
    return null;
});


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticated: (state, action) => {
            state.loading = false;
            state.redirect = '/';
            state.token = action.payload;
        },
        showAuthMessage: (state, action) => {
            state.message = action.payload;
            state.showMessage = true;
            state.loading = false;
        },
        hideAuthMessage: (state) => {
            state.message = '';
            state.showMessage = false;
        },
        signOutSuccess: (state) => {
            state.loading = false;
            // state.token = null;
            // state.redirect = '/';
        },
        showLoading: (state) => {
            state.loading = true;
        },
        signInSuccess: (state, action) => {
            state.loading = false;
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder

            .addCase(signIn.pending, (state) => {
                state.loading = true;
            })
            .addCase(signIn.fulfilled, (state, action) => {
                state.loading = false;
                const listModules = LIST_MODULE;
                let arrEntity = [];
               
                listModules.forEach((elm) => {
                    let entity = ManageLocalStorage.get(elm, 'entities');
                    if (entity != null)
                        entity.forEach((e) => {
                            arrEntity.push(e);
                        });
                });
                if (arrEntity.length > 0) {
                    // call modal notice - list article in localstorage
                    notificationArticle();
                }
                state.redirect = '/';
                state.token = action.payload;
            })
            .addCase(signIn.rejected, (state, action) => {
                state.message = action.payload;
                state.showMessage = true;
                state.loading = false;
            })
            .addCase(signOut.fulfilled, (state) => {
                state.loading = false;
                // state.token = null;
                // state.redirect = '/';
            })
            .addCase(signOut.rejected, (state) => {
                state.loading = false;
                state.token = null;
                state.redirect = '/';
            })
    },
});

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess } =
    authSlice.actions;

export default authSlice.reducer;
